import axios from 'axios';
import { ID_TOKEN_KEY } from '../constants';
const token = sessionStorage.getItem(ID_TOKEN_KEY);

const {BASE_URL} = require('../constants');
const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
   
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear()
      window.location.replace('/') // Redirect to homepage
      return
    }
    return Promise.reject(error);
  }
);



export const getTrips = () => {
  return axiosInstance.get(`/trips`);
};

export const getTripsCityDealers = (ids) => {
  return axiosInstance.post(`/trips/filter-trips`,{ids});
}
export const getTripsForFranchise = (ids) => {
  return axiosInstance.post(`/trips/filter-trips`,{ids});
}
export const createCityDealer = (user,name,email,password,state,currentUUid) => {
  return axiosInstance.post(`/city_dealer/create`, { uuid: user.uid, name, email, state, password, currentUser: currentUUid});
}
export const createFranchise = (user,name,email,password,state,currentUUid) => {
  return axiosInstance.post(`/franchise/create`, { uuid: user.uid, name, email, state, password, currentUser: currentUUid});
}
export const getCityDealers = (uid) => {
  return axiosInstance.post(`/city_dealer/getcitydealers`,{uid});
}
export const getFranchise = () => {
  return axiosInstance.get(`/franchise/getFranchise`)
}

export const deleteDriver = (uid) => {
  return axiosInstance.delete(`/drivers/driver/${uid}`)
}
export const deleteCityDealer = (uid) => {
  return axiosInstance.delete(`/city_dealer/${uid}`)
}
export const getDrivers = () => {
    return axiosInstance.get(`/drivers`)
}
export const getDriverForCityDealers = () =>{
  return axiosInstance.get(`/city_dealer`)
} 
export const getDriverForFranchise = () => {
  return axiosInstance.get(`/franchise`)
}
export const getCustomers = () => {
    return axiosInstance.get(`/customers`)
}

export const getLocation = (driverKey) => {
  return axiosInstance.get(`/drivers/location/${driverKey}`)
}

export const getAdmins = () => {
  return axiosInstance.get(`/get-admins`)
}

export const updatePassword = (email,newPassword) => {
  return axiosInstance.post('/admin/update-password',{ email, newPassword })
}