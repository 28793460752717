import React, { useState } from 'react';
import { ID_TOKEN_KEY, EXPIRATION_TIME_KEY } from './constants';

const ChildSidebar = ({ currentClaim }) => {
  const [hoveredButton, setHoveredButton] = useState('');

  const handleSidebarButtonHover = (buttonName) => {
    setHoveredButton(buttonName);
  };

  const handleSidebarButtonLeave = () => {
    setHoveredButton('');
  };

  const sidebarButtonStyle = {
    fontSize: '1.7rem',
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'Italian',
    padding: '1.5em',
    transition: 'background-color 0.3s ease',
  };

  const sidebarButtonHoverStyle = {
    color: 'pink',
  };

  return (
    <div className="sidebar-container" style={{ height: '2.2em', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1em' }}>
      <span
        className="sidebar-button"
        onClick={() => {
          window.location.href = '/drivers';
        }}
        style={{
          ...sidebarButtonStyle,
          ...(hoveredButton === 'drivers' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('drivers')}
        onMouseLeave={handleSidebarButtonLeave}
      >
        Drivers
      </span>
      <span
        className="sidebar-button"
        onClick={() => {
          window.location.href = '/trips';
        }}
        style={{
          ...sidebarButtonStyle,
          ...(hoveredButton === 'trips' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('trips')}
        onMouseLeave={handleSidebarButtonLeave}
      >
        Trips
      </span>
      {currentClaim === 'admin' && (
        <>
        <span
        className="sidebar-button"
        onClick={() => {
          window.location.href = 'update-password';
        }}
        style={{
          ...sidebarButtonStyle,
          ...(hoveredButton === 'updatePassword' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('updatePassword')}
        onMouseLeave={handleSidebarButtonLeave}
      >
        Update Password
      </span>
        <span
        className="sidebar-button"
        onClick={() => {
          window.location.href = '/customers';
        }}
        style={{
          ...sidebarButtonStyle,
          ...(hoveredButton === 'customers' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('customers')}
        onMouseLeave={handleSidebarButtonLeave}
      >
        Customers
      </span>
          <span
            className="sidebar-button"
            onClick={() => {
              window.location.href = '/createcitydealer';
            }}
            style={{
              ...sidebarButtonStyle,
              ...(hoveredButton === 'createCityDealer' ? sidebarButtonHoverStyle : null),
            }}
            onMouseEnter={() => handleSidebarButtonHover('createCityDealer')}
            onMouseLeave={handleSidebarButtonLeave}
          >
            Create City Dealer
          </span>
          <span
            className="sidebar-button"
            onClick={() => {
              window.location.href = '/displayFranchise';
            }}
            style={{
              ...sidebarButtonStyle,
              ...(hoveredButton === 'displayFranchise' ? sidebarButtonHoverStyle : null),
            }}
            onMouseEnter={() => handleSidebarButtonHover('displayFranchise')}
            onMouseLeave={handleSidebarButtonLeave}
          >
            display franchise
          </span>
          {/*  */}
          <span
        className="sidebar-button"
        onClick={() => {
          window.location.href = '/createfranchise';
        }}
        style={{
          ...sidebarButtonStyle,
          ...(hoveredButton === 'createFranchise' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('createFranchise')}
        onMouseLeave={handleSidebarButtonLeave}
      >
       Create Franchise
      </span>
          <span
            className="sidebar-button"
            onClick={() => {
              window.location.href = '/cityDealer';
            }}
            style={{
              ...sidebarButtonStyle,
              ...(hoveredButton === 'cityDealers' ? sidebarButtonHoverStyle : null),
            }}
            onMouseEnter={() => handleSidebarButtonHover('cityDealers')}
            onMouseLeave={handleSidebarButtonLeave}
          >
            City Dealers
          </span>
        </>
      )}
      {currentClaim === 'city_dealer' && (<> 
      <span
        className="sidebar-button"
        onClick={() => {
          window.location.href = '/createfranchise';
        }}
        style={{
          ...sidebarButtonStyle,
          ...(hoveredButton === 'createFranchise' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('createFranchise')}
        onMouseLeave={handleSidebarButtonLeave}
      >
       Create Franchise
      </span>
      <span
            className="sidebar-button"
            onClick={() => {
              window.location.href = '/displayFranchise';
            }}
            style={{
              ...sidebarButtonStyle,
              ...(hoveredButton === 'displayFranchise' ? sidebarButtonHoverStyle : null),
            }}
            onMouseEnter={() => handleSidebarButtonHover('displayFranchise')}
            onMouseLeave={handleSidebarButtonLeave}
          >
            display franchise
          </span>
         
        </>)}
      <span
        className="sidebar-button"
        onClick={() => {
          sessionStorage.removeItem(ID_TOKEN_KEY);
          sessionStorage.removeItem(EXPIRATION_TIME_KEY);
          window.location.href = '/admin';
        }}
        style={{
          ...sidebarButtonStyle,
          fontSize: "1.3rem",
          ...(hoveredButton === 'logout' ? sidebarButtonHoverStyle : null),
        }}
        onMouseEnter={() => handleSidebarButtonHover('logout')}
        onMouseLeave={handleSidebarButtonLeave}
      >
        Logout
      </span>
    </div>
  );
};

export default ChildSidebar;
